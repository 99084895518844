import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import { useUser } from '@/hooks/useUser';
import { useFlag } from '@simplywallst/unleash';
import loadable from '@loadable/component';

const GuestRegistrationModal = loadable(
  () => import('@/components/GuestRegistrationModal')
);

interface RegistrationModalContextType {
  shouldShowRego: boolean;
  showRegoModal: () => void;
}

const RegistrationModalContext = createContext<
  RegistrationModalContextType | undefined
>(undefined);

export const useRegistrationModal = () => {
  const context = useContext(RegistrationModalContext);
  if (!context) {
    throw new Error(
      'useRegistrationModal must be used within a RegistrationModalProvider'
    );
  }
  return context;
};

interface RegistrationModalProviderProps {
  children: ReactNode;
}

export const RegistrationModalProvider = ({
  children,
}: RegistrationModalProviderProps) => {
  const { data: user } = useUser();
  const annoyRegoFlag = useFlag('mono-annoy-registration-modal');

  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const shouldShowRego =
    annoyRegoFlag.variant.name === 'variant' && !user.loggedIn;

  const showRegoModal = useCallback(() => {
    if (shouldShowRego) {
      setShowRegistrationModal(true);
    }
  }, [shouldShowRego]);

  return (
    <RegistrationModalContext.Provider
      value={{
        shouldShowRego,
        showRegoModal,
      }}
    >
      {children}
      {showRegistrationModal && (
        <GuestRegistrationModal
          canClose
          onClose={() => setShowRegistrationModal(false)}
        />
      )}
    </RegistrationModalContext.Provider>
  );
};
